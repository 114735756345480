import { useTranslator } from "~/i18n/context.tsx";
import classes from "./intro.module.css";

type IntroProps = {
	readonly onStartTryItOut: () => void;
	readonly onStart: () => void;
};

function Intro({ onStartTryItOut, onStart }: IntroProps) {
	const t = useTranslator();
	return (
		<div className={classes.intro}>
			<button type="button" onClick={onStartTryItOut}>
				<span>{t("Don't have a kit yet?")}</span>
				{t("Try it out")}
			</button>
			<button type="button" onClick={onStart}>
				<span>{t("I have a kit.")}</span>
				{t("Get started")}
			</button>
			<a
				href="https://www.amazon.com.au"
				target="_blank"
				rel="noopener noreferrer"
			>
				{/* Spans are for flex centring when using space between */}
				<span />
				{t("Buy a kit")}
				<span />
			</a>
		</div>
	);
}

export default Intro;
