import { render } from "preact";
import { StrictMode } from "preact/compat";
import { init as sentryInit, browserTracingIntegration } from "@sentry/react";
import { optionalRuntimeEnv, requiredEnv } from "./utils/ensure-env.ts";
import App from "./app/app.tsx";
import packageInfo from "../package.json";
import "./styles/global.css";

console.log("App Version", packageInfo.version);

const sentryDsn = optionalRuntimeEnv("SENTRY_DSN");
if (sentryDsn) {
	const sentryEnvironment = optionalRuntimeEnv("SENTRY_ENVIRONMENT");
	const sentryRelease = optionalRuntimeEnv("SENTRY_RELEASE");
	sentryInit({
		dsn: sentryDsn,
		environment: sentryEnvironment,
		release: sentryRelease,
		integrations: [browserTracingIntegration()],
		normalizeDepth: 6,
		// TODO: Check on performance impacts?
		// Samples for every session in general. Don't want samples for non-error
		// replaysSessionSampleRate: 0,
		// // We want every error session sampled
		// replaysOnErrorSampleRate: 1.0,
	});
}

// Fix vh for mobile browsers. See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateViewHeight() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
}
updateViewHeight();
window.addEventListener("resize", updateViewHeight);

render(
	<StrictMode>
		<App
			userAgent={navigator.userAgent || navigator.vendor || "unknown"}
			// TODO: Should have a dedicated api url for premade kits
			apiUrl={requiredEnv("VITE_API_URL")}
		/>
	</StrictMode>,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	document.getElementById("root")!,
);

// console.error("TODO: Remove this testing code");
// const myUnusedVar = "prevents accidental commit"
// async function waitForReactRender() {
// 	return new Promise((resolve) => setTimeout(resolve, 0));
// }
// async function waitForElement<T extends HTMLElement>(selector: string) {
// 	return new Promise<T>((resolve) => {
// 		const interval = setInterval(() => {
// 			const element = document.querySelector<T>(selector);
// 			if (element) {
// 				clearInterval(interval);
// 				resolve(element);
// 			}
// 		}, 100);
// 	});
// }
// (async () => {
// 	await waitForReactRender();
// 	const buttons = document.evaluate(
// 		"//button[contains(., 'I have a kit')]",
// 		document,
// 		null,
// 		XPathResult.ANY_TYPE,
// 		null,
// 	);
// 	const button = buttons.iterateNext();
// 	button?.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const input = await waitForElement<HTMLInputElement>('input[type="text"]');
// 	input.value = "HP9B5Z466PUG";
// 	const submit = await waitForElement<HTMLButtonElement>(
// 		'button[type="submit"]',
// 	);
// 	submit.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const inputKits = await waitForElement<HTMLInputElement>('input[type="number"]');
// 	inputKits.value = "2";
// 	const submitKits = await waitForElement<HTMLButtonElement>(
// 		'button[type="submit"]',
// 	);
// 	submitKits.dispatchEvent(new MouseEvent("click", { bubbles: true }));

// 	const { default: image } = await import("./house-2.jpg?url");
// 	const response = await fetch(image);
// 	const blob = await response.blob();
// 	const uploadLabel = (await waitForElement('input[type="file"]')).closest("label")!;
// 	const event = new Event("drop");
// 	(event as any).dataTransfer = {
// 		files: [new File([blob], "tiger.png", { type: "image/png" })],
// 	};
// 	uploadLabel.dispatchEvent(event);

// 	const layout = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="4x3"]',
// 	);
// 	layout.dispatchEvent(new MouseEvent("click", { bubbles: true }));
// 	const next = await waitForElement<HTMLButtonElement>(
// 		'button[aria-label="Adjustments"]',
// 	);
// 	next.dispatchEvent(new MouseEvent("click", { bubbles: true }));
// })();
