function ContrastIcon() {
	return (
		<svg
			viewBox="-1 -1 26 26"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			strokeWidth="1"
		>
			<g transform="translate(-222.000000, -709.000000)">
				<g transform="translate(194.000000, 687.000000)">
					<path d="M40,22 C46.627417,22 52,27.372583 52,34 C52,40.627417 46.627417,46 40,46 C33.372583,46 28,40.627417 28,34 C28,27.372583 33.372583,22 40,22 Z M40,23.5 C34.2010101,23.5 29.5,28.2010101 29.5,34 C29.5,39.7989899 34.2010101,44.5 40,44.5 C45.7989899,44.5 50.5,39.7989899 50.5,34 C50.5,28.2010101 45.7989899,23.5 40,23.5 Z M40.5,27 C44.3659932,27 47.5,30.1340068 47.5,34 C47.5,37.8659932 44.3659932,41 40.5,41 L40.5,41 Z" />
				</g>
			</g>
		</svg>
	);
}

export default ContrastIcon;
