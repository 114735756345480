function getRenderMeasurements(
	canvasWidth: number,
	canvasHeight: number,
	numberOfBricksX: number,
	numberOfBricksY: number,
) {
	const brickSizePixels = Math.min(
		canvasWidth / numberOfBricksX,
		canvasHeight / numberOfBricksY,
	);
	const ceilBrickSizePixels = Math.ceil(brickSizePixels);
	return {
		/** @deprecated this was marked deprecated, but i dont know wht */
		ceilBrickSizePixels,
		brickSizePixels,
	};
}

export default getRenderMeasurements;
