import type { PictureConfiguration, Palette } from "@brickme/project-core/src";
import {
	originPosition,
	multiplySize,
	getEnhanceDetailOptions,
	premadeKit,
} from "@brickme/project-core/src";
import isDetailFilterSupported from "~/utils/is-detail-filter-supported.ts";
import { getDefaultLayout } from "./layout-permutations.ts";

const defaultContrast = 0.15;
const defaultSaturation = 0;
const defaultBrightness = 0;

type Options = {
	readonly numberOfKits: number;
	readonly kitsPalette: Palette;
};

function createDefaultPaletteMode({
	kitsPalette,
}: Pick<Options, "kitsPalette">): PictureConfiguration["paletteMode"] {
	return {
		type: "hybrid",
		palette: kitsPalette.map((p) => p.brick),
		numberOfExtraColours: 0,
	};
}

function createDefaultPictureConfiguration({
	numberOfKits,
	kitsPalette,
}: Options): PictureConfiguration {
	const numberOfBasePlates = getDefaultLayout(numberOfKits);
	const basePlateSize = premadeKit.basePlateSize;
	return {
		imageZoom: 1,
		imageZoomOffset: originPosition,
		basePlateSize,
		numberOfBasePlates,
		numberOfBricks: multiplySize(numberOfBasePlates, basePlateSize),
		paletteMode: createDefaultPaletteMode({ kitsPalette }),
		removeBackground: undefined,
		enhanceFaces: false,
		fixFaceColours: false,
		detailFilter: isDetailFilterSupported
			? getEnhanceDetailOptions().find((o) => o.value !== undefined)?.value
			: undefined,
		brightness: defaultBrightness,
		contrast: defaultContrast,
		saturation: defaultSaturation,
		pen: [],
		updatedAt: Date.now(),
	};
}

export { createDefaultPaletteMode };
export default createDefaultPictureConfiguration;
