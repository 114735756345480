import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type ConfirmResetModalProps = Pick<ModalProps, "open" | "onRequestClose"> & {
	readonly onConfirm: () => void;
};

function ConfirmResetModal({
	open,
	onConfirm,
	onRequestClose,
}: ConfirmResetModalProps) {
	const t = useTranslator();
	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Are you sure?")}
			size="small"
			cancelActionButton
			actionButtons={[
				{
					label: t("Yes, reset"),
					type: "button",
					onClick: onConfirm,
					variant: "danger",
				},
			]}
		>
			{t("You will lose all of your current progress")}
		</Modal>
	);
}

export default ConfirmResetModal;
