import type { JSX } from "preact/jsx-runtime";
import { sum, range } from "lodash-es";
import type {
	BrickPosition,
	NumberOfBricks,
	PixelPosition,
} from "@brickme/project-core/src";

type MouseOrTouchEvent =
	| JSX.TargetedMouseEvent<HTMLCanvasElement>
	| JSX.TargetedTouchEvent<HTMLCanvasElement>;

function getCanvasPixelPosition(
	e: MouseOrTouchEvent,
): PixelPosition | undefined {
	const touches: readonly { clientX: number; clientY: number }[] =
		"clientX" in e ? [e] : range(0, e.touches.length).map((i) => e.touches[i]);

	// This happens for ontouchend. But also good to have it as a safeguard in case occurs elsewhere.
	if (touches.length === 0) {
		return undefined;
	}
	const clientX = sum(touches.map((t) => t.clientX)) / touches.length;
	const clientY = sum(touches.map((t) => t.clientY)) / touches.length;
	const rect = e.currentTarget.getBoundingClientRect();
	return { x: clientX - rect.left, y: clientY - rect.top };
}

function canvasPositionToBrick(
	canvas: HTMLCanvasElement,
	pixelPosition: PixelPosition,
	numberOfBricks: NumberOfBricks,
): BrickPosition {
	const bounding = canvas.getBoundingClientRect();
	const xRatio = pixelPosition.x / bounding.width;
	const yRatio = pixelPosition.y / bounding.height;
	const x = Math.min(
		Math.floor(xRatio * numberOfBricks.width),
		numberOfBricks.width - 1,
	);
	const y = Math.min(
		Math.floor(yRatio * numberOfBricks.height),
		numberOfBricks.height - 1,
	);
	return { x, y };
}

export type { MouseOrTouchEvent };
export { getCanvasPixelPosition, canvasPositionToBrick };
