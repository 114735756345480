import { clsx } from "clsx";
import type { ReactNode, HTMLAttributes, ForwardedRef } from "preact/compat";
import { forwardRef } from "preact/compat";
import classes from "./simple-form.module.css";

type SimpleFormProps = HTMLAttributes<HTMLFormElement> & {
	readonly children: ReactNode;
};

function SimpleForm(
	{ children, className, ...rest }: SimpleFormProps,
	ref: ForwardedRef<HTMLFormElement>,
) {
	return (
		<form
			ref={ref}
			{...rest}
			className={clsx(classes["simple-form"], className)}
		>
			{children}
		</form>
	);
}

export default forwardRef(SimpleForm);
