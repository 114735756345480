import { useState, useEffect } from "preact/hooks";
import { memo } from "preact/compat";
import { range } from "lodash-es";

type DimensionsImageProps = {
	readonly width: number;
	readonly height: number;
};

function drawRoundedRect(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
) {
	ctx.moveTo(x + radius, y);
	ctx.beginPath();
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);
	ctx.closePath();
}

function DimensionsImage({ width, height }: DimensionsImageProps) {
	// const cols = range(0, width);
	const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
	useEffect(() => {
		if (!canvasRef) {
			return;
		}
		const c = canvasRef.getContext("2d");
		if (!c) {
			throw new Error("No context 2d");
		}

		c.clearRect(0, 0, canvasRef.width, canvasRef.height);
		const border = 1;
		const canvasWidth = canvasRef.width - 2 * border;
		const canvasHeight = canvasRef.height - 2 * border;

		const margin = 0; // Math.max(1, 6 - Math.max(width, height));
		const widthCellSize = (canvasWidth - margin * (width - 1)) / width;
		const heightCellSize = (canvasHeight - margin * (height - 1)) / height;
		const cellSize = Math.min(widthCellSize, heightCellSize);
		const xStart =
			border + (canvasWidth - ((cellSize + margin) * width - margin)) / 2;
		const yStart =
			border + (canvasHeight - ((cellSize + margin) * height - margin)) / 2;

		c.lineWidth = Math.sqrt(margin);
		c.strokeStyle = "black";
		range(0, width).forEach((x) => {
			range(0, height).forEach((y) => {
				drawRoundedRect(
					c,
					xStart + x * (cellSize + margin),
					yStart + y * (cellSize + margin),
					cellSize,
					cellSize,
					cellSize * 0.3,
				);
				c.stroke();
			});
		});
	}, [canvasRef, width, height]);
	return <canvas ref={setCanvasRef} width={48} height={48} />;
}

export default memo(DimensionsImage);
