function BrightnessIcon() {
	return (
		<svg
			viewBox="-0.5 -0.5 24 24"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
		>
			<path
				d="m18.678875 14.476583333333334 2.3 -2.3a0.9583333333333334 0.9583333333333334 0 0 0 0 -1.3550833333333334l-2.3 -2.3V5.270833333333334a0.9583333333333334 0.9583333333333334 0 0 0 -0.9583333333333334 -0.9583333333333334H14.470833333333333l-2.3 -2.3a0.9583333333333334 0.9583333333333334 0 0 0 -1.3550833333333334 0l-2.3 2.3H5.262208333333334a0.9583333333333334 0.9583333333333334 0 0 0 -0.9583333333333334 0.9583333333333334v3.2525833333333334l-2.3 2.3a0.9583333333333334 0.9583333333333334 0 0 0 0 1.3550833333333334l2.3 2.3V17.729166666666668a0.9583333333333334 0.9583333333333334 0 0 0 0.9583333333333334 0.9583333333333334h3.2525833333333334l2.3 2.3a0.9583333333333334 0.9583333333333334 0 0 0 1.3550833333333334 0l2.3 -2.3h4.210916666666667Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="M5.741375 11.5a5.75 5.75 0 1 0 11.5 0 5.75 5.75 0 1 0 -11.5 0Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
		</svg>
	);
}

export default BrightnessIcon;
