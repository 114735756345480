import { useState } from "preact/hooks";
import { useTranslator } from "~/i18n/context.tsx";
import ColourIcon from "~/components/icons/colour-icon.tsx";
import BottomMenu from "../bottom-menu.tsx";
import { useCurrentBrickedBuild, usePicture } from "../context.tsx";
import type { Mode } from "../mode.ts";
import BrightnessIcon from "./brightness-icon.tsx";
import BrightnessSheet from "./brightness-sheet.tsx";
import ColoursSheet from "./colours-sheet.tsx";
import ContrastIcon from "./contrast-icon.tsx";
import ContrastSheet from "./contrast-sheet.tsx";
import CrossIcon from "./cross-icon.tsx";
import DetailIcon from "./detail-icon.tsx";
import DetailSheet from "./detail-sheet.tsx";
import SaturationIcon from "./saturation-icon.tsx";
import SaturationSheet from "./saturation-sheet.tsx";
import TickIcon from "./tick-icon.tsx";

type AdjustmentsMenuProps = {
	readonly mode: Mode;
};

function AdjustmentsMenu({ mode }: AdjustmentsMenuProps) {
	const t = useTranslator();
	const [active, setActive] = useState<
		"detail" | "brightness" | "contrast" | "saturation" | "colours" | undefined
	>();
	const {
		picture: { removeBackground },
		patchPicture,
	} = usePicture();
	const bricked = useCurrentBrickedBuild();
	return (
		<>
			<BottomMenu
				buttons={[
					{
						id: "details",
						label: t("Detail"),
						icon: <DetailIcon />,
						onClick: () => setActive("detail"),
					},
					{
						id: "brightness",
						label: t("Brightness"),
						icon: <BrightnessIcon />,
						onClick: () => setActive("brightness"),
					},
					{
						id: "contrast",
						label: t("Contrast"),
						icon: <ContrastIcon />,
						onClick: () => setActive("contrast"),
					},
					...(mode === "colour"
						? [
								{
									id: "saturation",
									label: t("Saturation"),
									icon: <SaturationIcon />,
									onClick: () => setActive("saturation"),
								},
								{
									id: "colours",
									label: t("Colours"),
									icon: <ColourIcon />,
									onClick: () => setActive("colours"),
								},
							]
						: []),
					{
						id: "remove-bg",
						label: t("Remove BG"),
						icon: (() => {
							if (!removeBackground) {
								return <CrossIcon />;
							}
							if (
								bricked?.operationsMissingSources.includes("Remove background")
							) {
								return <div>{t("Loading")}...</div>;
							}
							return <TickIcon />;
						})(),
						onClick: () =>
							patchPicture({
								removeBackground: removeBackground
									? undefined
									: { noBrick: true },
							}),
					},
				]}
			/>
			<BrightnessSheet
				open={active === "brightness"}
				onDone={() => setActive(undefined)}
			/>
			<ContrastSheet
				open={active === "contrast"}
				onDone={() => setActive(undefined)}
			/>
			{mode === "colour" && (
				<>
					<SaturationSheet
						open={active === "saturation"}
						onDone={() => setActive(undefined)}
					/>
					<ColoursSheet
						open={active === "colours"}
						onDone={() => setActive(undefined)}
					/>
				</>
			)}
			<DetailSheet
				open={active === "detail"}
				onDone={() => setActive(undefined)}
			/>
		</>
	);
}

export default AdjustmentsMenu;
