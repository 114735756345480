function DetailIcon() {
	return (
		<svg viewBox="-0.5 -0.5 24 24" xmlns="http://www.w3.org/2000/svg">
			<path
				d="m0.4791666666666667 22.520833333333336 12.458333333333334 -12.458333333333334"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m19.645833333333336 7.1875 2.875 0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m18.6875 4.3125 1.9166666666666667 -1.9166666666666667"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m15.8125 3.354166666666667 0 -2.875"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m12.9375 4.3125 -1.9166666666666667 -1.9166666666666667"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m11.979166666666668 7.1875 -2.875 0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m15.8125 10.0625 0 2.875"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m18.6875 9.104166666666668 1.9166666666666667 1.9166666666666667"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
		</svg>
	);
}

export default DetailIcon;
