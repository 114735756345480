import type { ReactNode } from "preact/compat";
import { createContext } from "preact/compat";
import type { Palette, TransportPalette } from "@brickme/project-core/src";
import { transportPaletteToCore } from "@brickme/project-core/src";
import { useMemo, useContext, useState, useEffect } from "preact/hooks";
import { useApiClient } from "~/api/context.tsx";

type ReferenceContextValue = {
	readonly systemPalette: Palette | undefined;
};

const ReferenceContext = createContext<ReferenceContextValue | undefined>(
	undefined,
);

type PaletteQuery = {
	readonly brickPalette: TransportPalette;
};

type ReferenceProviderProps = {
	readonly children: ReactNode;
};

function ReferenceProvider({ children }: ReferenceProviderProps) {
	const [systemPalette, setSystemPalette] = useState<Palette | undefined>();
	const value = useMemo(
		() => ({
			systemPalette,
		}),
		[systemPalette],
	);
	const apiClient = useApiClient();
	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			const { brickPalette } = await apiClient.query<PaletteQuery>(
				`query {
                    brickPalette {
                        colour
                        identifier
                    }
                }`,
				undefined,
				{ signal: abortController.signal },
			);
			setSystemPalette(transportPaletteToCore(brickPalette));
		})();
		return () => {
			abortController.abort();
		};
	}, [apiClient]);

	return (
		<ReferenceContext.Provider value={value}>
			{children}
		</ReferenceContext.Provider>
	);
}

function useSystemPalette() {
	const context = useContext(ReferenceContext);
	if (!context) {
		throw new Error("useSystemPalette must be used within a ReferenceProvider");
	}
	return context.systemPalette;
}

function useIsSystemPaletteLoaded() {
	const context = useContext(ReferenceContext);
	if (!context) {
		throw new Error(
			"useIsSystemPaletteLoaded must be used within a ReferenceProvider",
		);
	}
	return !!context.systemPalette;
}

export { ReferenceProvider, useIsSystemPaletteLoaded, useSystemPalette };
