import type { ReactNode } from "preact/compat";
import { useEffect, useRef } from "preact/hooks";
import { clsx } from "clsx";
import { compact } from "lodash-es";
import type { ButtonProps } from "~/components/button.tsx";
import Button from "~/components/button.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import CloseIcon from "./icons/cross-icon.tsx";
import NewWindowIcon from "./icons/new-window-icon.tsx";
import classes from "./modal.module.css";

type SubmitActionButtonProps = {
	readonly type: "submit";
};

type GeneralButtonActionButtonProps = {
	readonly type: "button";
	readonly onClick: () => void;
};

type LinkActionButtonProps = {
	readonly href: string;
	readonly newWindow?: boolean;
};

type ActionButtonProps = {
	readonly label: string;
	readonly disabled?: boolean;
	readonly variant: ButtonProps["variant"];
} & (
	| SubmitActionButtonProps
	| GeneralButtonActionButtonProps
	| LinkActionButtonProps
);

type ModalProps = {
	readonly containerAs?: "div" | "form";
	readonly containerProps?: Record<string, unknown>;
	readonly id?: string;
	readonly title: string;
	readonly open: boolean;
	readonly onRequestClose: () => void;
	readonly size?: "small" | "medium" | "large" | "full";
	readonly cancelActionButton?: boolean | string;
	readonly actionButtons?: readonly ActionButtonProps[];
	readonly working?: boolean;
	readonly bodyClassName?: string;
	readonly children: ReactNode;
};

function Modal({
	id,
	containerAs: ContainerAs = "div",
	containerProps,
	title,
	cancelActionButton,
	actionButtons,
	open,
	bodyClassName,
	onRequestClose,
	size = "medium",
	working,
	children,
}: ModalProps) {
	const dialogRef = useRef<HTMLDialogElement>(null);

	const onRequestCloseRef = useRef(onRequestClose);
	onRequestCloseRef.current = onRequestClose;
	const workingRef = useRef(working);
	workingRef.current = working;

	useEffect(() => {
		const { current: dialog } = dialogRef;
		if (!dialog) {
			return;
		}

		const onCancel = (e: Event) => {
			e.preventDefault();
			if (!workingRef.current) {
				onRequestCloseRef.current();
			}
		};

		if (open) {
			dialog.showModal();
			dialog.addEventListener("cancel", onCancel);
		} else {
			dialog.close();
		}

		return () => {
			dialog.removeEventListener("cancel", onCancel);
		};
	}, [open, workingRef, onRequestCloseRef]);

	const t = useTranslator();
	const allActions = compact([
		cancelActionButton && {
			label:
				typeof cancelActionButton === "string"
					? cancelActionButton
					: t("Cancel"),
			variant: "secondary" as const,
			type: "button" as const,
			onClick: onRequestClose,
		},
		...(actionButtons ?? []),
	]);

	const labelId = id ? `${id}-label` : undefined;

	return (
		<dialog
			aria-labelledby={labelId}
			ref={dialogRef}
			className={clsx(classes.modal, classes[`modal-${size}`])}
		>
			<ContainerAs className={classes["modal-container"]} {...containerProps}>
				<div className={classes["modal-header"]}>
					<h2 id={labelId}>{title}</h2>
					<Button
						aria-label="Close"
						type="button"
						disabled={working}
						variant="text"
						onClick={onRequestClose}
					>
						<CloseIcon />
					</Button>
				</div>
				<div className={clsx(classes["modal-body"], bodyClassName)}>
					{open && children}
				</div>
				{allActions.length > 0 && (
					<div className={classes["modal-actions"]}>
						{allActions.map((a) => {
							const commonProps = {
								variant: a.variant,
								disabled: a.disabled,
								working,
							};

							if ("type" in a && a.type === "submit") {
								return (
									<Button key={a.label} type="submit" {...commonProps}>
										{a.label}
									</Button>
								);
							}
							if ("type" in a) {
								return (
									<Button
										key={a.label}
										type="button"
										onClick={a.onClick}
										{...commonProps}
									>
										{a.label}
									</Button>
								);
							}
							return (
								<Button href={a.href} newWindow={a.newWindow} {...commonProps}>
									{a.label}
									{a.newWindow && (
										<>
											{" "}
											<NewWindowIcon />
										</>
									)}
								</Button>
							);
						})}
					</div>
				)}
			</ContainerAs>
		</dialog>
	);
}

export type { ModalProps };
export default Modal;
