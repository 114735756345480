import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";
import Button from "~/components/button.tsx";
import TextField from "~/components/forms/text-field.tsx";
import FormRow from "~/components/forms/form-row.tsx";
import FormErrors from "~/components/forms/form-errors.tsx";
import SimpleForm from "~/components/forms/simple-form.tsx";

type EnterNumberOfKitsProps = {
	readonly onComplete: (numberOfKits: number) => void;
	readonly onCancel: () => void;
};

function EnterNumberOfKits({ onComplete, onCancel }: EnterNumberOfKitsProps) {
	const t = useTranslator();

	const { isWorking, formError, formProps } = useForm({
		onSubmit: (values: FormValues) => {
			const numberOfKits = values.numberOfKits as string;
			onComplete(parseInt(numberOfKits, 10));
		},
	});

	return (
		<SimpleForm {...formProps}>
			<h2>{t("Number of kits")}</h2>
			<div>
				{t(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vestibulum tempus 
                tellus sollicitudin porta. Nulla eu arcu faucibus, facilisis ante et, condimentum massa. 
                Nulla eu sodales lectus, quis lobortis diam. Nulla enim ex, sodales et malesuada in, 
                malesuada eget eros. Phasellus quis risus volutpat justo gravida sagittis in vulputate mi.`)}
			</div>
			<FormRow>
				<TextField
					name="numberOfKits"
					type="number"
					min="1"
					max="100"
					step="1"
					label={t("Number of kits")}
					disabled={isWorking}
					required
				/>
			</FormRow>
			<FormErrors errors={[formError]} />
			<Button type="submit" variant="primary" working={isWorking}>
				{t("Submit")}
			</Button>
			<Button
				type="button"
				variant="secondary"
				size="small"
				onClick={onCancel}
				disabled={isWorking}
			>
				{t("Go back")}
			</Button>
		</SimpleForm>
	);
}

export default EnterNumberOfKits;
