import type { ReactNode } from "preact/compat";
import classes from "./form-field.module.css";

type FormFieldProps = {
	readonly label: string;
	readonly fieldId: string;
	readonly children: ReactNode;
};

function FormField({ label, fieldId, children }: FormFieldProps) {
	return (
		<div className={classes["form-field"]}>
			<label htmlFor={fieldId} className={classes["form-field-label"]}>
				{label}
			</label>
			{children}
		</div>
	);
}

export type { FormFieldProps };
export default FormField;
