import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type AddColourModalProps = Pick<ModalProps, "open" | "onRequestClose"> & {
	readonly onConfirm: () => void;
};

function AddColourModal({
	open,
	onConfirm,
	onRequestClose,
}: AddColourModalProps) {
	const t = useTranslator();
	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Add colour")}
			size="medium"
			cancelActionButton
			actionButtons={[
				{
					label: t("Add colour"),
					variant: "primary",
					type: "button",
					onClick: onConfirm,
				},
			]}
		>
			{t(`You can add colour to your design. Praesent ultricies at dui eu pulvinar.
			Sed est sapien, auctor eget mi id, sollicitudin porttitor tellus. Ut dui
			nisi, dictum id odio non, ultrices consequat sem. Vestibulum elementum
			pharetra libero vel convallis.`)}
		</Modal>
	);
}

export default AddColourModal;
