import SliderInput from "~/components/slider-input.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { usePicture } from "../context.tsx";
import type { SheetProps } from "../sheet.tsx";
import Sheet from "../sheet.tsx";

function BrightnessSheet(props: Pick<SheetProps, "open" | "onDone">) {
	const t = useTranslator();
	const { picture, patchPictureDebounced } = usePicture();

	return (
		<Sheet title={t("Brightness")} {...props}>
			<form onSubmit={(e) => e.preventDefault()}>
				<SliderInput
					min={0}
					max={100}
					step={1}
					value={Math.round(picture.brightness * 100)}
					onChange={(newValue) =>
						patchPictureDebounced({
							brightness: newValue / 100,
						})
					}
				/>
			</form>
		</Sheet>
	);
}

export default BrightnessSheet;
