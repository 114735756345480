function ColourIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-0.5 -0.5 24 24"
			fill="none"
		>
			<g>
				<path
					d="M3.70875 20.125a11.020833333333334 11.020833333333334 0 1 1 15.582500000000001 0 5.270833333333334 5.270833333333334 0 0 1 -7.4558333333333335 0l-4.0633333333333335 -4.053750000000001a0.9583333333333334 0.9583333333333334 0 0 0 -1.35125 1.35125A1.9166666666666667 1.9166666666666667 0 0 1 3.70875 20.125Z"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M17.000833333333333 7.599583333333333a1.4375 1.4375 0 1 1 -1.4375 -1.4375 1.4375 1.4375 0 0 1 1.4375 1.4375Z"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M17.25 12.343333333333334a0.9583333333333334 0.9583333333333334 0 1 0 1.9166666666666667 0 0.9583333333333334 0.9583333333333334 0 1 0 -1.9166666666666667 0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M15.563333333333333 19.00375a1.9166666666666667 1.9166666666666667 0 1 1 1.9166666666666667 -1.9166666666666667 1.9166666666666667 1.9166666666666667 0 0 1 -1.9166666666666667 1.9166666666666667Z"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
				<path
					d="M9.80375 5.14625a1.4375 1.4375 0 1 1 -1.4375 1.4375 1.4375 1.4375 0 0 1 1.4375 -1.4375Z"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1}
				/>
			</g>
		</svg>
	);
}

export default ColourIcon;
