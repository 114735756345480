/* eslint-disable no-bitwise */
import type { BrickColour } from "./bricks.ts";
import {
	rgbToHsl,
	rgbToRgba,
	rgbToLab,
	rgbHexStringToRgbNumber,
} from "../utils/colour-conversions.ts";

const redMask24 = 0xff0000;
const greenMask24 = 0x00ff00;
const blueMask24 = 0x0000ff;

function hexStringToBrickColour(
	hexString: string,
	identifier: number,
	symbol?: string,
): BrickColour {
	const rgb = rgbHexStringToRgbNumber(hexString);
	const r = (rgb & redMask24) >> 16;
	const g = (rgb & greenMask24) >> 8;
	const b = rgb & blueMask24;
	const hsl = rgbToHsl(r, g, b);
	// This is a rough hack. Assumes uniform visual perception over rgb space
	// which isn't true.
	// TODO: Find proper method
	const isVisuallyDark = (r + g + b) / 3 < 0xff * 0.5;
	return {
		identifier,
		symbol,
		rgba: rgbToRgba(rgb, 0xff),
		r,
		g,
		b,
		isVisuallyDark,
		lab: rgbToLab(r, g, b),
		hexString,
		lightness: hsl.l,
		saturation: hsl.s,
		hue: hsl.h,
	};
}

export { hexStringToBrickColour };
