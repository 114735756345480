import { findOnlyIndexOrThrow } from "@dhau/lang-extras";
import { useState } from "preact/hooks";
import { Fragment } from "preact/compat";
import { clsx } from "clsx";
import type { Palette } from "@brickme/project-core/src";
import Button from "~/components/button.tsx";
import ChevronRightIcon from "~/components/icons/chevron-right-icon.tsx";
import ChevronLeftIcon from "~/components/icons/chevron-left-icon.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import PaletteIcon from "./palette-icon.tsx";
import CheckIcon from "./check-icon.tsx";
import GridIcon from "./grid-icon.tsx";
import PictureRender from "./render/picture-render.tsx";
import LayoutMenu from "./layout/layout-menu.tsx";
import AdjustmentsMenu from "./adjustments/adjustments-menu.tsx";
import {
	EditorProvider,
	useActiveRenderMode,
	usePicture,
	useRenderModeControls,
} from "./context.tsx";
import CompleteMenu from "./complete/complete-menu.tsx";
import type { Mode } from "./mode.ts";
import buildIconImage from "./build-icon.png";
import originalIconImage from "./original-icon.png";
import ConfirmResetModal from "./confirm-reset-modal.tsx";
import ConfirmRestartModal from "./confirm-restart-modal.tsx";
import type { SourceImage } from "./source-image.ts";
import classes from "./editor.module.css";

const manualControlSourceRenderId = "manual-control-source-render";

type EditorProps = {
	readonly onRestart: () => void;
	readonly numberOfKits: number;
	readonly code: string | undefined;
};

function Editor({ numberOfKits, onRestart, code }: EditorProps) {
	const t = useTranslator();
	const steps = [
		{
			id: "layout" as const,
			label: t("Layout"),
			icon: <GridIcon />,
		},
		{
			id: "adjustments" as const,
			label: t("Adjustments"),
			icon: <PaletteIcon />,
		},
		{
			id: "complete" as const,
			label: t("Complete"),
			icon: <CheckIcon />,
		},
	];

	const [activeStepIndex, setActiveStepIndex] = useState(0);
	const activeStep = steps[activeStepIndex];

	const [mode, setMode] = useState<Mode>("kit-only");
	const onChangeMode = (newMode: Mode) => {
		setMode(newMode);
		setActiveStepIndex(
			findOnlyIndexOrThrow(steps, (s) => s.id === "adjustments"),
		);
	};

	// Switch back/forth between source and bricked
	const { activeSourceRenderModeRequests } = useActiveRenderMode();
	const { requestSourceRenderMode, withdrawSourceRenderModeRequest } =
		useRenderModeControls();
	const isManualSourceRenderModeActive = activeSourceRenderModeRequests.has(
		manualControlSourceRenderId,
	);
	const onSourceRenderToggle = () => {
		if (isManualSourceRenderModeActive) {
			withdrawSourceRenderModeRequest(manualControlSourceRenderId);
		} else {
			requestSourceRenderMode(manualControlSourceRenderId);
		}
	};

	// Reset
	const [pendingReset, setPendingReset] = useState(false);
	const { resetPicture } = usePicture();

	// Restart
	const [pendingRestart, setPendingRestart] = useState(false);

	return (
		<>
			<ConfirmResetModal
				open={pendingReset}
				onRequestClose={() => setPendingReset(false)}
				onConfirm={() => {
					resetPicture();
					setPendingReset(false);
				}}
			/>
			<ConfirmRestartModal
				open={pendingRestart}
				onRequestClose={() => setPendingRestart(false)}
				onConfirm={() => {
					onRestart();
					setPendingReset(false);
				}}
			/>
			<div className={classes.editor}>
				<header>
					<div className={classes["steps-container"]}>
						<Button
							type="button"
							variant="text"
							size="snug"
							onClick={() => {
								if (activeStepIndex === 0) {
									setPendingRestart(true);
								} else {
									setActiveStepIndex((p) => Math.max(0, p - 1));
								}
							}}
							aria-label="Previous step"
						>
							<ChevronLeftIcon />
						</Button>
						<ol
							className={clsx(
								classes.steps,
								activeStepIndex < Math.floor(steps.length / 2) &&
									classes["active-to-left"],
								activeStepIndex > Math.floor(steps.length / 2) &&
									classes["active-to-right"],
								activeStepIndex > 0 &&
									activeStepIndex < steps.length - 1 &&
									classes["active-in-centre"],
							)}
						>
							{steps.map((s, i) => (
								<Fragment key={s.label}>
									<li
										className={clsx(
											classes.step,
											i === activeStepIndex && classes["step-active"],
										)}
									>
										<button
											type="button"
											onClick={() => setActiveStepIndex(i)}
											aria-label={s.label}
										>
											{s.icon}
										</button>
									</li>
									{i < steps.length - 1 && <li className={classes.joiner} />}
								</Fragment>
							))}
						</ol>
						<Button
							type="button"
							variant="text"
							size="snug"
							onClick={() =>
								setActiveStepIndex((p) => Math.min(steps.length - 1, p + 1))
							}
							disabled={activeStepIndex >= steps.length - 1}
							aria-label="Next step"
						>
							<ChevronRightIcon />
						</Button>
					</div>

					<h2>{activeStep.label}</h2>
				</header>
				<main className={classes.main}>
					<PictureRender />
					<button
						type="button"
						onClick={() => setPendingReset(true)}
						className={clsx(
							classes["overlay-control"],
							classes["reset-control"],
						)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="-4 -4 28 28"
						>
							<line
								x1="0"
								y1="0"
								x2="24"
								y2="24"
								stroke="black"
								strokeWidth="1"
							/>
							<line
								x1="24"
								y1="0"
								x2="0"
								y2="24"
								stroke="black"
								strokeWidth="1"
							/>
						</svg>
						{t("Reset")}
					</button>
					<button
						type="button"
						onClick={onSourceRenderToggle}
						className={clsx(
							classes["overlay-control"],
							classes["source-render-toggle"],
						)}
					>
						{isManualSourceRenderModeActive ? (
							<>
								<img src={buildIconImage} alt={t("Bricked image")} />
								<div>{t("Show bricked")}</div>
							</>
						) : (
							<>
								<img src={originalIconImage} alt={t("Original image")} />
								<div>{t("Show original")}</div>
							</>
						)}
					</button>
				</main>
				<section>
					{activeStep.id === "layout" && (
						<LayoutMenu numberOfKits={numberOfKits} />
					)}
					{activeStep.id === "adjustments" && <AdjustmentsMenu mode={mode} />}
					{activeStep.id === "complete" && (
						<CompleteMenu
							code={code}
							numberOfKits={numberOfKits}
							mode={mode}
							onChangeMode={onChangeMode}
						/>
					)}
				</section>
			</div>
		</>
	);
}

type EditorContainerProps = EditorProps & {
	readonly sourceImage: SourceImage;
	readonly systemPalette: Palette;
};

function EditorContainer({
	sourceImage,
	numberOfKits,
	systemPalette,
	...rest
}: EditorContainerProps) {
	return (
		<EditorProvider
			sourceImage={sourceImage}
			systemPalette={systemPalette}
			numberOfKits={numberOfKits}
		>
			<Editor numberOfKits={numberOfKits} {...rest} />
		</EditorProvider>
	);
}

export default EditorContainer;
