import BottomMenu from "../bottom-menu.tsx";
import { usePicture } from "../context.tsx";
import { getLayoutPermutations } from "../layout-permutations.ts";
import DimensionsImage from "./dimensions-image.tsx";

type LayoutMenuProps = {
	readonly numberOfKits: number;
};

function LayoutMenu({ numberOfKits }: LayoutMenuProps) {
	const layouts = getLayoutPermutations(numberOfKits);
	const { picture, patchPicture } = usePicture();
	const { numberOfBasePlates } = picture;

	return (
		<BottomMenu
			activeId={`${numberOfBasePlates.width}x${numberOfBasePlates.height}`}
			buttons={layouts.map(({ width, height }) => ({
				id: `${width}x${height}`,
				label: `${width}x${height}`,
				icon: <DimensionsImage width={width} height={height} />,
				onClick: () =>
					patchPicture({
						numberOfBasePlates: { width, height },
					}),
			}))}
		/>
	);
}

export default LayoutMenu;
