function TickIcon() {
	return (
		<svg
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			strokeWidth="1.5"
			fill="none"
		>
			<path
				d="M23.25.749,8.158,22.308a2.2,2.2,0,0,1-3.569.059L.75,17.249"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default TickIcon;
