function SaturationIcon() {
	return (
		<svg
			viewBox="-0.5 -0.5 24 24"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
		>
			<path
				d="M12.320333333333334 0.830875a1.13275 1.13275 0 0 0 -1.6406666666666667 0C9.136750000000001 2.451416666666667 2.875 9.334166666666667 2.875 14.375c0 4.7638750000000005 3.861125 8.145833333333334 8.625 8.145833333333334s8.625 -3.3819583333333334 8.625 -8.145833333333334C20.125 9.334166666666667 13.86325 2.451416666666667 12.320333333333334 0.830875Z"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m11.5 22.520833333333336 0 -22.041666666666668"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m14.375 3.1414166666666667 0 18.943375"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m17.25 6.988166666666666 0 13.549875"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
		</svg>
	);
}

export default SaturationIcon;
