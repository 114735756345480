import classes from "./form-errors.module.css";

type FormErrorsProps = {
	readonly errors: readonly (string | undefined)[];
};

function FormErrors({ errors }: FormErrorsProps) {
	const showErrors = errors.filter(Boolean);
	if (showErrors.length === 0) {
		return null;
	}
	return <div className={classes["form-errors"]}>{showErrors.join(", ")}</div>;
}

export default FormErrors;
