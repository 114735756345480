import type { ModalProps } from "~/components/modal.tsx";
import Modal from "~/components/modal.tsx";
import { useTranslator } from "~/i18n/context.tsx";

type BuyAKitModalProps = Pick<ModalProps, "open" | "onRequestClose">;

function BuyAKitModal({ open, onRequestClose }: BuyAKitModalProps) {
	const t = useTranslator();

	return (
		<Modal
			open={open}
			onRequestClose={onRequestClose}
			title={t("Buy a kit")}
			size="medium"
			cancelActionButton
			actionButtons={[
				{
					label: t("Buy a kit"),
					variant: "primary",
					href: "http://www.amazon.com.au",
				},
			]}
		>
			{t(`To buy a kit you can Praesent ultricies at dui eu pulvinar. Sed est
			sapien, auctor eget mi id, sollicitudin porttitor tellus. Ut dui nisi,
			dictum id odio non, ultrices consequat sem. Vestibulum elementum pharetra
			libero vel convallis.`)}
		</Modal>
	);
}

export default BuyAKitModal;
