import SliderInput from "~/components/slider-input.tsx";
import { useTranslator } from "~/i18n/context.tsx";
import { usePalettes, usePicture } from "../context.tsx";
import type { SheetProps } from "../sheet.tsx";
import Sheet from "../sheet.tsx";

function ColoursSheet(props: Pick<SheetProps, "open" | "onDone">) {
	const t = useTranslator();
	const { picture, patchPictureDebounced } = usePicture();
	const { kitsPalette, kitPlusExtrasPalette } = usePalettes();

	return (
		<Sheet title={t("Colours")} {...props}>
			<form onSubmit={(e) => e.preventDefault()}>
				<SliderInput
					min={kitsPalette.length}
					max={kitPlusExtrasPalette.length}
					step={1}
					value={
						picture.paletteMode.type === "hybrid"
							? picture.paletteMode.palette.length +
								picture.paletteMode.numberOfExtraColours
							: kitsPalette.length
					}
					onChange={(newValue) =>
						patchPictureDebounced({
							paletteMode: {
								type: "hybrid",
								palette: kitsPalette.map((p) => p.brick),
								numberOfExtraColours: newValue - kitsPalette.length,
							},
						})
					}
				/>
			</form>
		</Sheet>
	);
}

export default ColoursSheet;
