import { useApiClient } from "~/api/context.tsx";
import Button from "~/components/button.tsx";
import FormErrors from "~/components/forms/form-errors.tsx";
import FormRow from "~/components/forms/form-row.tsx";
import SimpleForm from "~/components/forms/simple-form.tsx";
import TextField from "~/components/forms/text-field.tsx";
import type { FormValues } from "~/hooks/use-form.ts";
import useForm from "~/hooks/use-form.ts";
import { useTranslator } from "~/i18n/context.tsx";

type CheckPremadeKitCodeAvailabilityQuery = {
	readonly checkPremadeKitCodeAvailability: "DOESNT_EXIST" | "OK" | "USED";
};

type CheckPremadeKitCodeAvailabilityVariables = {
	readonly code: string;
};

type EnterCodeProps = {
	readonly onComplete: (code: string) => void;
	readonly onCancel: () => void;
};

function EnterCode({ onComplete, onCancel }: EnterCodeProps) {
	const t = useTranslator();
	const apiClient = useApiClient();
	const { isWorking, formError, formProps } = useForm({
		onSubmit: async (values: FormValues) => {
			const code = values.code as string;
			const { checkPremadeKitCodeAvailability } = await apiClient.query<
				CheckPremadeKitCodeAvailabilityQuery,
				CheckPremadeKitCodeAvailabilityVariables
			>(
				`
					query ($code: String!) {
						checkPremadeKitCodeAvailability(code: $code)
					}
				`,
				{
					code,
				},
			);
			if (checkPremadeKitCodeAvailability === "DOESNT_EXIST") {
				throw new Error(t("Code doesn't exist"));
			}
			if (checkPremadeKitCodeAvailability === "USED") {
				throw new Error(t("Code has been used too many times"));
			}
			onComplete(code);
		},
	});

	return (
		<SimpleForm {...formProps}>
			<h2>{t("Enter code")}</h2>
			<div>
				{t(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vestibulum tempus 
                tellus sollicitudin porta. Nulla eu arcu faucibus, facilisis ante et, condimentum massa. 
                Nulla eu sodales lectus, quis lobortis diam. Nulla enim ex, sodales et malesuada in, 
                malesuada eget eros. Phasellus quis risus volutpat justo gravida sagittis in vulputate mi.`)}
			</div>
			<FormRow>
				<TextField
					type="text"
					label={t("Code")}
					name="code"
					disabled={isWorking}
					minLength={1}
					required
				/>
			</FormRow>
			<FormErrors errors={[formError]} />
			<Button type="submit" variant="primary" working={isWorking}>
				{t("Submit")}
			</Button>
			<Button
				type="button"
				variant="secondary"
				size="small"
				onClick={onCancel}
				disabled={isWorking}
			>
				{t("Go back")}
			</Button>
		</SimpleForm>
	);
}

export default EnterCode;
