import type { Bitmap } from "../bitmap/index.ts";
import type { GenericSize } from "../model/index.ts";
import type { DrawBounds } from "./draw-bounds.ts";
import getRenderMeasurements from "./get-render-measurements.ts";

// Key names are same values, but it's to help guide the users of the function
type Options = {
	readonly buildMap: Pick<Bitmap, "width" | "height">;
} & (
	| {
			readonly drawBounds: Pick<DrawBounds, "width" | "height">;
	  }
	| {
			readonly canvas: GenericSize<number>;
	  }
);

function createUtilityCanvas<T>(
	options: Options,
	factory: (width: number, height: number) => T,
) {
	const { ceilBrickSizePixels } = getRenderMeasurements(
		"drawBounds" in options ? options.drawBounds.width : options.canvas.width,
		"drawBounds" in options ? options.drawBounds.height : options.canvas.height,
		options.buildMap.width,
		options.buildMap.height,
	);
	return factory(
		Math.max(options.buildMap.width, ceilBrickSizePixels),
		Math.max(options.buildMap.height, ceilBrickSizePixels),
	);
}

export default createUtilityCanvas;
