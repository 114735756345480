import { useState } from "preact/compat";
import type { ImageZoomOffset, PixelPosition } from "@brickme/project-core/src";
import {
	arePositionsClose,
	addPositions,
	roundPosition,
	subtractPositions,
	constrainImageZoomOffset,
} from "@brickme/project-core/src";
import type { MouseOrTouchEvent } from "~/utils/canvas.ts";
import {
	getCanvasPixelPosition,
	canvasPositionToBrick,
} from "~/utils/canvas.ts";
import {
	usePicture,
	useRenderModeControls,
	useSourceImageBitmap,
} from "../context.tsx";

const sourceRenderModeId = "zoom-drag";

type ZoomDragStart = {
	readonly mousePosition: PixelPosition;
	readonly imageZoomOffset: ImageZoomOffset;
};

function useImageZoomDragging() {
	const { picture, patchPictureDebounced } = usePicture();
	const { imageZoomOffset, numberOfBricks } = picture;
	const { requestSourceRenderMode, withdrawSourceRenderModeRequest } =
		useRenderModeControls();

	const [zoomDraggingStart, setZoomDraggingStart] = useState<
		ZoomDragStart | undefined
	>(undefined);
	// TODO: Don't request low quality render or show hand drag icon if no space possible.
	// i.e. aspect ratio of source must equal aspect ratio number of base plates and zoom == 1
	const onZoomDragStart = (e: MouseOrTouchEvent) => {
		const mousePosition = getCanvasPixelPosition(e);
		if (!mousePosition) {
			return;
		}

		requestSourceRenderMode(sourceRenderModeId);
		setZoomDraggingStart({
			mousePosition,
			imageZoomOffset,
		});
	};

	const sourceImage = useSourceImageBitmap();
	const onZoomDragMove = (e: MouseOrTouchEvent) => {
		if (!zoomDraggingStart) {
			console.error("Invalid state - moving while start not called");
			return;
		}

		const mousePosition = getCanvasPixelPosition(e);
		if (!mousePosition) {
			return;
		}

		const mouseOffset = subtractPositions(
			mousePosition,
			zoomDraggingStart.mousePosition,
		);

		const brickOffset = canvasPositionToBrick(
			e.currentTarget,
			mouseOffset,
			numberOfBricks,
		);
		const roundedOffset = roundPosition(
			addPositions(zoomDraggingStart.imageZoomOffset, brickOffset),
		);

		const newOffset = constrainImageZoomOffset(
			sourceImage,
			picture,
			roundedOffset,
		);
		// redux action is very heavy (or maybe it's just the listening consequences)
		// worth preventing it
		if (arePositionsClose(newOffset, imageZoomOffset, 0.5)) {
			return;
		}

		patchPictureDebounced({
			imageZoomOffset: newOffset,
		});
	};
	const onZoomDragEnd = () => {
		withdrawSourceRenderModeRequest(sourceRenderModeId);
		setZoomDraggingStart(undefined);
	};

	// const mouseHandlers = (() => {
	// 	if (isPinching || isDisplayZoomPinching) {
	// 		return { state: "pinching" };
	// 	}

	// 	if (isImageZoomActive) {
	// 		if (imageDragging.isDragging) {
	// 			return {
	// 				state: "zoom-dragging",
	// 				onMouseMove: imageDragging.onDragMove,
	// 				onTouchMove: imageDragging.onDragMove,
	// 				onMouseUp: imageDragging.onDragEnd,
	// 				onMouseLeave: imageDragging.onDragEnd,
	// 				onTouchEnd: imageDragging.onDragEnd,
	// 				onTouchCancel: imageDragging.onDragEnd,
	// 			};
	// 		}
	// 		return {
	// 			state: "zoom-drag",
	// 			onMouseDown: imageDragging.onDragStart,
	// 			onTouchStart: imageDragging.onDragStart,
	// 		};
	// 	}

	// 	return undefined;
	// })();

	const isDragging = !!zoomDraggingStart;
	return {
		isDragging,
		imageZoomCanvasHandlers: isDragging
			? {
					onMouseMove: onZoomDragMove,
					onTouchMove: onZoomDragMove,
					onMouseUp: onZoomDragEnd,
					onMouseLeave: onZoomDragEnd,
					onTouchEnd: onZoomDragEnd,
					onTouchCancel: onZoomDragEnd,
				}
			: {
					onMouseDown: onZoomDragStart,
					onTouchStart: onZoomDragStart,
				},
	};
}

export default useImageZoomDragging;
