import type { ReactNode } from "preact/compat";
import { BottomSheet } from "react-spring-bottom-sheet";
import classes from "./sheet.module.css";
import { clsx } from "clsx";

type SheetProps = {
	readonly open: boolean;
	readonly onDone: () => void;
	// readonly onReject?: () => void;
	readonly title: string;
	readonly children: ReactNode;
};

function Sheet({
	open,
	onDone,
	// onReject,
	title,
	children,
}: SheetProps) {
	return (
		<BottomSheet
			open={open}
			onDismiss={onDone}
			blocking={false}
			header={
				<div className={classes["sheet-header"]}>
					{/* <button
						type="button"
						onClick={onReject}
						className="sheet-button sheet-button-reject"
					>
						Cancel
					</button> */}
					<div className={classes["sheet-spacer"]} />
					<h4>{title}</h4>
					<button
						type="button"
						onClick={onDone}
						className={clsx(
							classes["sheet-button"],
							classes["sheet-button-approve"],
						)}
					>
						Done
					</button>
				</div>
			}
		>
			<div className={classes["sub-section-sheet-content"]}>{children}</div>
		</BottomSheet>
	);
}

export type { SheetProps };
export default Sheet;
