function CrossIcon() {
	return (
		<svg
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			strokeWidth="1.5"
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			stroke="black"
		>
			<path d="M0.75 23.249L23.25 0.749" />
			<path d="M23.25 23.249L0.75 0.749" />
		</svg>
	);
}

export default CrossIcon;
