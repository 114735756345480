import type { HTMLAttributes } from "preact/compat";
import type { FormFieldProps } from "./form-field.tsx";
import FormField from "./form-field.tsx";
import classes from "./inputs-shared.module.css";

type TextFieldProps = Pick<FormFieldProps, "label"> & {
	readonly type?: "text" | "password" | "email" | "number";
	readonly name: string;
	readonly autoComplete?:
		| "off"
		| "current-password"
		| "email"
		| "new-password"
		| "one-time-code"
		| "tel"
		| "given-name"
		| "family-name";
} & Pick<
		HTMLAttributes<HTMLInputElement>,
		| "placeholder"
		| "disabled"
		| "required"
		| "min"
		| "max"
		| "step"
		| "minLength"
	>;

function TextField({ label, name, type = "text", ...rest }: TextFieldProps) {
	const fieldId = `${name}-field`;
	return (
		<FormField fieldId={fieldId} label={label}>
			<input
				id={fieldId}
				name={name}
				type={type}
				className={classes.input}
				{...rest}
			/>
		</FormField>
	);
}

export default TextField;
