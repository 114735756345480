function InstructionsIcon() {
	return (
		<svg viewBox="-0.5 -0.5 24 24" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.270833333333334 19.645833333333336h8.701666666666666a1.9166666666666667 1.9166666666666667 0 0 0 1.4557083333333334 -0.6708333333333333l1.8390416666666667 -2.1457083333333333a1.9166666666666667 1.9166666666666667 0 0 0 0.46191666666666664 -1.2458333333333333V5.270833333333334h-12.458333333333334Z"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="M20.604166666666668 3.354166666666667a0.9583333333333334 0.9583333333333334 0 0 0 -0.9583333333333334 -0.9583333333333334h-5.221000000000001a3.190291666666667 3.190291666666667 0 0 0 -5.845833333333333 0H3.354166666666667a0.9583333333333334 0.9583333333333334 0 0 0 -0.9583333333333334 0.9583333333333334v18.208333333333336a0.9583333333333334 0.9583333333333334 0 0 0 0.9583333333333334 0.9583333333333334h16.291666666666668a0.9583333333333334 0.9583333333333334 0 0 0 0.9583333333333334 -0.9583333333333334Z"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m14.854166666666668 8.145833333333334 -4.3125 0"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m8.145833333333334 8.145833333333334 0.4791666666666667 0"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m14.854166666666668 11.979166666666668 -4.3125 0"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m8.145833333333334 11.979166666666668 0.4791666666666667 0"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m13.416666666666668 15.8125 -2.875 0"
				fill="none"
				stroke="#000000"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
			<path
				d="m8.145833333333334 15.8125 0.4791666666666667 0"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1}
			/>
		</svg>
	);
}

export default InstructionsIcon;
