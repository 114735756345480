function ChevronUpIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11.79"
			height="6.63"
			viewBox="0 0 11.79 6.63"
		>
			<polygon points="0 1.96 1.55 0 5.9 3.44 10.24 0 11.79 1.96 5.9 6.63 0 1.96" />
		</svg>
	);
}

export default ChevronUpIcon;
