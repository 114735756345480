import { sortBy } from "lodash-es";
import { premadeKit } from "@brickme/project-core/src";

const allLayouts = [
	{ width: 3, height: 3 },
	{ width: 3, height: 4 },
	{ width: 4, height: 3 },
	{ width: 3, height: 5 },
	{ width: 5, height: 3 },
	{ width: 3, height: 6 },
	{ width: 6, height: 3 },
	{ width: 4, height: 4 },
	{ width: 4, height: 5 },
	{ width: 5, height: 4 },
	{ width: 4, height: 6 },
	{ width: 6, height: 4 },
	{ width: 5, height: 5 },
	{ width: 5, height: 6 },
	{ width: 6, height: 5 },
	{ width: 5, height: 7 },
	{ width: 7, height: 5 },
	{ width: 4, height: 7 },
	{ width: 7, height: 4 },
	{ width: 4, height: 8 },
	{ width: 8, height: 4 },
	{ width: 6, height: 6 },
];

const maxLayoutDimension = Math.max(
	...allLayouts.flatMap(({ width, height }) => [width, height]),
);

const maxConsideredNumberOfKits = 4;

function getLayoutPermutations(numberOfKits: number) {
	const calcNumberOfKits = Math.min(numberOfKits, maxConsideredNumberOfKits);
	const previousNumBaseplates =
		(calcNumberOfKits - 1) * premadeKit.numberOfBasePlates;
	const numBaseplates = calcNumberOfKits * premadeKit.numberOfBasePlates;
	return sortBy(
		allLayouts.filter(({ width, height }) => {
			// Eyal doesn't want single kit options to display if choose 2 kits
			const area = width * height;
			return area > previousNumBaseplates && area <= numBaseplates;
		}),
		[({ width, height }) => width * height, ({ width }) => width],
	);
}

function getDefaultLayout(numberOfKits: number) {
	const squarePermutations = getLayoutPermutations(numberOfKits).filter(
		({ width, height }) => width === height,
	);
	return squarePermutations[Math.floor((squarePermutations.length - 1) / 2)];
}

export { maxLayoutDimension, getDefaultLayout, getLayoutPermutations };
